import { PPA_CLUB_ID } from '@/modules/players/v2/tabs/tournaments/constants';
import { MatchProps } from '@/modules/results/types';
import { MatchInfoV2 } from '@/modules/ticker/types';

export const shouldShowMatchStartTimeTicker = (match: MatchInfoV2) => {
	if (match.bracketLevelId === '2') {
		return true;
	}

	const hasRequiredPlayers = () => {
		if (match.formatId === '2') {
			return !!match.teamOnePlayerOneUuid && !!match.teamTwoPlayerOneUuid;
		} else if (match.formatId === '1') {
			return !!match.teamOnePlayerOneUuid && !!match.teamOnePlayerTwoUuid && !!match.teamTwoPlayerOneUuid && !!match.teamTwoPlayerTwoUuid;
		}
		return false;
	};

	const isAssignedOrStartedCompleted = () => {
		const isAssignedToCourt = !!match.localDateMatchAssignedToCourt;
		const isStartedOrCompleted = match.matchStatus === 2 || match.matchStatus === 4;
		return isAssignedToCourt || isStartedOrCompleted;
	};

	return hasRequiredPlayers() && isAssignedOrStartedCompleted();
};

export const shouldShowMatchStartTimeResults = (match: MatchProps) => {
	if (match.club_uuid.toLowerCase() === PPA_CLUB_ID.toLowerCase() && match.bracket_level_title === 'Pro') {
		return true;
	}

	const hasRequiredPlayers = () => {
		if (match.format_title === 'Singles') {
			return !!match.team_one_player_one_uuid && !!match.team_two_player_one_uuid;
		} else if (match.format_title === 'Doubles') {
			return (
				!!match.team_one_player_one_uuid &&
				!!match.team_one_player_two_uuid &&
				!!match.team_two_player_one_uuid &&
				!!match.team_two_player_two_uuid
			);
		}
		return false;
	};

	const isAssignedCourt = () => {
		const isAssignedToCourt = !!match.local_date_match_assigned_to_court;
		return isAssignedToCourt;
	};

	return hasRequiredPlayers() && isAssignedCourt();
};
